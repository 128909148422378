/* eslint-disable no-case-declarations */

import { ControlType } from 'constants/controlType';
import { DATE_PICKER_CONTROL_FORMAT } from 'constants/date';
import moment from 'moment';

export const parsePayload = (control, value, onChange, handleUpload, handleRemove, fileLoading) => {
  const { type } = control.answerData;

  switch (type) {
    case ControlType.Input:
    case ControlType.TextArea:
      return {
        name: control.id,
        onChange: (value) => {
          onChange(value, control.id);
        },
        value,
      };
    case ControlType.DatePicker:
      return {
        name: control.id,
        onChange: (value) => {
          const parsedValue = value ? moment(value).format(DATE_PICKER_CONTROL_FORMAT) : '';
          onChange(parsedValue, control.id);
        },
        value: value ? moment(value, DATE_PICKER_CONTROL_FORMAT) : '',
      };
    case ControlType.RadioGroup:
      const radioOptions = control.answerData.options;
      return {
        options: radioOptions?.map((opt) => ({
          ...opt,
          value: opt.key,
          label: opt.value,
        })),
        value,
        name: control.id,
        onChange: (event) => {
          const { value } = event.target;
          onChange(value, control.id);
        },
      };
    case ControlType.Checkbox:
      const checkBoxOptions = control.answerData.options;
      return {
        options: checkBoxOptions?.map((opt) => ({
          ...opt,
          value: opt.key,
          label: opt.value,
        })),
        value,
        name: control.id,
        onChange: (event) => {
          const { value } = event.target;
          onChange(value, control.id);
        },
      };
    case ControlType.MultiSelect:
      let currentValues;
      if (value !== 0) {
        const valSet = new Set(value);
        currentValues = control.answerData.options.filter((option) => valSet.has(option.key));
      }

      return {
        onChange: (value) => {
          const valueSet = new Set(value);
          const answers = control.answerData.options
            .filter((option) => valueSet.has(option.value))
            ?.map((answer) => answer.key);
          onChange(answers, control.id);
        },
        options: control.answerData.options?.map((option) => ({
          ...option,
          label: option.value,
        })),
        value: currentValues,
      };
    case ControlType.DropDown:
      return {
        onChange: (value) => {
          const answer = control.answerData.options.find((option) => option.value === value);
          onChange(answer.key, control.id);
        },
        options: control.answerData.options?.map((option) => ({
          ...option,
          label: option.value,
        })),
        value: control.answerData.options.find((option) => option.key === value)?.value,
      };
    case ControlType.FileUpload:
      return {
        loading: fileLoading,
        accept: '',
        handleUpload: (event) => {
          handleUpload(event, control.id);
        },
        handleRemove: (fileId) => {
          handleRemove(fileId, control.id);
        },
        uploadedFiles: [...value],
      };
    case ControlType.EmptySpace:
      return null;
    default:
      return null;
  }
};
